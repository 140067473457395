<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:selection="selectedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @rowSelect="onRowSelect"
      @rowUnselect="onRowUnselect"
      @rowSelectAll="onRowSelectAll"
      @rowUnselectAll="onRowUnselectAll"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        field="selected"
        style="min-width: 3rem; flex: initial"
        header=""
        selectionMode="multiple"
      />
      <Column
        field="si_no"
        header="No. Faktur"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="order_between"
        field="si_at"
        header="Tgl. Faktur"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.si_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pelanggan.nama"
        sort-field="pelanggan"
        filter-field="pelanggan"
        header="Pelanggan"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="golongan.nama"
        sort-field="golongan"
        filter-field="golongan"
        header="Golongan"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="salesman.nama"
        sort-field="salesman"
        filter-field="salesman"
        header="Salesman"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pajak_no"
        header="No. Pajak"
        style="min-width: 10rem"
        sortable
      >
      </Column>
      <Column
        field="jumlah"
        header="Total"
        type="numeric"
        dataType="numeric"
        style="
          min-width: 70px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <!-- <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 200px;
        "
      >
        <template #body="{ data }">
          <Button
            :disabled="data.status === 'done'"
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  components: {
    CalendarFilter,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedRows: null,
      faktur: null,
      options: this.gridOptions,
      filters: {
        si_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pelanggan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        salesman: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        golongan: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      menuPrint: [
        {
          label: 'Faktur penjualan',
          command: () => {
            this.$emit('printfaktur', this.faktur)
          },
        },
        {
          label: 'Surat penyerahan',
          command: () => {
            this.$emit('printspb', this.faktur)
          },
        },
      ],
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.order_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    toggle(event, data) {
      this.$refs.overlay_menu.toggle(event)
      this.faktur = { id: data.id, si_no: data.si_no.replace(/\//g, '-') }
    },
    onRowSelect() {
      const data = this.selectedRows
      let ids = []
      if (data) {
        data.forEach((e) => {
          ids.push(e.id)
        })
      }
      this.$emit('selects', ids)
    },
    onEmitData(data) {
      let ids = []
      if (data) {
        data.forEach((e) => {
          ids.push(e.id)
        })
      }
      this.$emit('selects', ids)
    },
    onRowUnselect() {
      const data = this.selectedRows
      this.onEmitData(data)
    },
    onRowSelectAll(event) {
      const data = event.data
      this.onEmitData(data)
    },
    onRowUnselectAll(event) {
      const data = event.data
      this.onEmitData(data)
    },
  },
}
</script>
