<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl"
        >Input No. Seri Faktur Pajak</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pajak / Input No. Seri Faktur Pajak</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-check-circle"
          label="Assign"
          @click="onAssignData"
        />
      </div>
      <grid-input-pajak-seri
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @delete="onConfirmDeletion"
        @edit="onShowEditDialog"
        @request="onRequestData"
        @selects="onSelectData"
      />
      <!-- @assign="onShowAssignDialog" -->
    </div>
    <!-- <Dialog
      header="Assign"
      v-model:visible="dialogAssign"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '75vw' }"
      :modal="true"
    >
      <form-pajak-seri-assign
        :item="form"
        :items="invoices"
        :loading="isLoadingAssign"
        @request="onRequestInvoice"
      />
    </Dialog> -->
    <Dialog
      header="Hapus No. Pajak"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >No. Pajak <strong>{{ form.pajak_no }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="dialogHapus = false"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import InputPajakSeriService from '@/services/InputPajakSeriService'
import GridInputPajakSeri from '@/components/pajak/GridInputPajakSeri'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'

export default {
  components: {
    GridInputPajakSeri,
    Hotkey,
  },
  data() {
    return {
      dataService: null,
      dialogGenerate: false,
      dialogHapus: false,
      dialogEdit: false,
      // dialogAssign: false,
      isLoading: false,
      isLoadingExport: false,
      isLoadingSave: false,
      // isLoadingAssign: false,
      items: [],
      invoices: [],
      selects: null,
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new InputPajakSeriService()
  },
  mounted() {
    this.onLoadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      let filters = this.options.filters
      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
          this.items.forEach((el) => {
            el.pajak_status = el.pajak_id > 1 ? 'Assigned' : ''
          })
        })
        .catch((err) => {
          errorHandler(err, 'Rekonsiliasi Keluaran', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onRequestInvoice(e) {
      this.options = e
      this.onLoadGridInvoice()
    },
    onAssignData() {
      if (this.selects) {
        if (this.selects.length > 0) {
          let ids = []
          this.selects.forEach((id) => {
            ids.push(id)
          })
          const data = { si_id: ids }

          this.dataService
            .assign(data)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Rekonsiliasi Keluaran',
                  detail: 'Invoice berhasil di-assign.',
                  life: 3000,
                })
                this.onLoadGridData()
              }
            })
            .catch((err) => {
              errorHandler(err, 'Rekonsiliasi Keluaran', this)
            })
          this.onLoadGridData()
        }
      }
    },
    onSelectData(data) {
      this.selects = data
    },
    onShowEditDialog(data) {
      this.form = data
      this.form.expired_at = new Date(data.expired_at)
      this.dialogEdit = true
    },
    /* onShowAssignDialog(data) {
      this.form = data
      this.dialogAssign = true
    }, */
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
  },
}
</script>
