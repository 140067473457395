import http from '../clients/Axios'

export default class InputPajakSeriService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        let new_filter = ''
        if (filters[el].value) {
          let key = el
          let val = filters[el].value
          if (key === 'pajak_status') {
            key = 'pajak_id'
            if (val.toLowerCase() === 'assigned') {
              val = 1
              new_filter = '&filter[' + key + ']>' + val
            } else {
              val = 0
              new_filter = '&filter[' + key + ']=' + val
            }
          } else {
            new_filter = '&filter[' + el + ']=' + filters[el].value
          }
          filters_param = filters_param + new_filter
        }
      })
    }
    // commented this for test
    filters_param += '&filter[status]=done'
    const res = await http.get(
      `/sales/invoice/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )
    return res.data
  }

  async assign(ids) {
    return await http.post(`/pajak/seri/assign/sales`, ids)
  }
}
